import React from "react";
import {promiseData, mobileSlider, handleThingsData} from "../../constant/Constant";
import iphone_shape from "../../assets/images/iphone-mockup-1.png";
import {UilCheck, UilArrowRight} from "@iconscout/react-unicons";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation} from "swiper";
import "swiper/css";
import "../../App.css";

const WorkFlow = () => {
	return (
		<div id="promises" className=" mt-10 lg:mt-20">
			<div className="grid lg:grid-cols-3 gap-5 xl:gap-0 min-h-[615px] overflow-hidden">
				<div className="h-full flex flex-col pt-5 ">
					<div className="text-slate-800 dark:text-white duration-500 text-2xl  font-bold  tracking-wide font-inter ">
						<h2 className="">We put your money to work, so you can put your mind at ease</h2>
					</div>
					<div className="mt-5 text-slate-700 duration-500 dark:text-gray-400 font-roboto">
						<ul className="space-y-2">
							{handleThingsData.map((list) => (
								<li key={list.id}>
									<p className="flex leading-6 items-start gap-2">
										{" "}
										<span className="bg-blue-600 w-4 mt-1 h-4 inline-flex items-center rounded-full text-white">
											{" "}
											<UilCheck className="" />{" "}
										</span>{" "}
										<span className="">{list.title}</span>{" "}
									</p>
									<ul className="ml-6">
										{list?.handle?.map((x) => (
											<li key={x.id} className="flex items-start gap-2">
												{" "}
												<span className=" mt- w-5 text-blue-600">
													{" "}
													<UilArrowRight className="" />{" "}
												</span>{" "}
												<span>{x.title}</span>{" "}
											</li>
										))}
									</ul>
								</li>
							))}
						</ul>
					</div>
				</div>
				<div>
					<div className="w-full h-full flex justify-center ">
						<div
							style={{backgroundImage: `url(${iphone_shape})`}}
							className="  w-[300px] px-4  pt-3.5 pb-5  bg-contain  relative  overflow-hidden bg-no-repeat "
						>
							<Swiper
								centeredSlides={true}
								autoplay={{
									delay: 2000,
									disableOnInteraction: false,
								}}
								speed={1000}
								loop
								modules={[Autoplay, Navigation]}
								className="mySwiper overflow-hidden rounded-[35px]"
							>
								{mobileSlider.map((slider) => (
									<SwiperSlide key={slider.id} className="">
										<img
											className="rounded-[35px] h-[99%] min-h-[575px] w-full"
											src={slider.sliderImg}
											alt=""
										/>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					</div>
				</div>
				<div>
					<div className="h-full flex flex-col pb-9 justify-end">
						<h2 className="text-slate-800 duration-500 dark:text-white text-2xl  font-bold  tracking-wide font-inter">
							Our promises to seller store investors
						</h2>
						<div className="mt-5 space-y-4 xl:space-y-6">
							{promiseData.map((promise) => (
								<div key={promise.id} className="flex   items-center gap-3">
									<img className="w-10" src={promise.img} alt="" />
									<p className="text-base font-roboto tracking-wider font-normal dark:text-gray-400 duration-500 text-slate-700">
										{promise.title}
									</p>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WorkFlow;
