export const CustomLabel = ({title}) => {
	return (
		<label htmlFor="" className="block mb-2 text-sm font-inter font-medium duration-500 text-slate-800 dark:text-gray-300">
			{title}
		</label>
	);
};

export const SelectBoxTitle = ({title}) => {
	return <h3 className="text-slate-800 duration-500 dark:text-gray-300 font-medium text-sm font-inter tracking-wide ">{title}</h3>;
};
