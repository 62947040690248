import React, {useEffect, useRef, useState} from "react";
import {UilTimes} from "@iconscout/react-unicons";
import {CustomLabel} from "./FormComponents";
import {toast} from "react-hot-toast";
const ModalForm = ({openModalForm, setOpenModalForm}) => {
	const [AddTransition, setAddTransition] = useState(false);
	const [loading, setLoading] = useState(false);
	const date = new Date();
	useEffect(() => {
		setTimeout(() => {
			setAddTransition(true);
		}, 1);
	}, []);
	const handleCloseModal = () => {
		setAddTransition(false);
		setTimeout(() => {
			setOpenModalForm(false);
		}, 500);
	};
	const modalFormRef = useRef(null);
	const scriptUrl = "https://script.google.com/macros/s/AKfycbx-cRmPaIC-xPDOd7PhbH5Zalsd_V3fpf9qydrCuaPSIBZ8YoXdEVGRXQvXKIDIuCQHoA/exec";
	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		const form = modalFormRef.current;
		const data = new FormData(form);
		fetch(scriptUrl, {method: "POST", body: data})
			.then((res) => {
				if (res) {
					toast.success("Successfully submitted", {id: 1, position: "top-right"});
					setLoading(false);
					e.target.reset();
					setAddTransition(false);
					setTimeout(() => {
						setOpenModalForm(false);
					}, 1500);
				}
			})
			.catch((err) => console.log(err));
	};
	return (
		<div
			className={`fixed top-0 duration-500 left-0 flex h-full min-h-screen w-full items-center justify-center bg-black
         ${AddTransition ? "bg-opacity-80" : "bg-opacity-0"} px-4 py-5 z-[55555]`}
		>
			<div className={`${AddTransition ? "opacity-100 scale-100" : "opacity-0 scale-50"} duration-500`}>
				<div className="w-[360px] sm:w-[36rem] xl:w-[44rem] bg-slate-50 dark:bg-slate-900 duration-500 p-5 sm:p-8 rounded-xl relative border dark:border-slate-800">
					<span
						onClick={handleCloseModal}
						className="text-white bg-gray-800 hover:bg-gray-700 duration-300 dark:bg-blue-600 dark:hover:bg-blue-700 inline-flex w-8 h-8 absolute top-2 right-2
             items-center justify-center rounded-full  cursor-pointer"
					>
						<UilTimes className="w-5 h-5" />
					</span>
					<div className="w-full">
						<form ref={modalFormRef} onSubmit={handleSubmit} className="w-full">
							<input name="Date Modified" type="text" defaultValue={date} hidden />
							<div className="pt-3">
								<h2 className="text-xl uppercase font-semibold text-gray-800 dark:text-white">Get in Touch With us</h2>
								<p className="text-sm text-gray-600 dark:text-gray-400">By Filling out this form</p>
							</div>
							<div className=" grid grid-cols-1 md:grid-cols-2 gap-4 my-5">
								<div className=" md:col-span-2 ">
									<CustomLabel title={"Full Name"} />
									<input
										type="text"
										name="Name"
										className="bg-white   dark:bg-slate-800 px-3 text-gray-500 dark:text-white border dark:border-slate-600 border-gray-200  dark:shadow-none rounded-md placeholder:text-sm tracking-wide w-full"
										required
									/>
								</div>
								<div className=" ">
									<CustomLabel title={"Email Address"} />
									<input
										type="email"
										name="Email"
										className="bg-white  dark:bg-slate-800 px-3 text-gray-500 dark:text-white border dark:border-slate-600 border-gray-200  dark:shadow-none rounded-md placeholder:text-sm tracking-wide w-full"
										required
									/>
								</div>
								<div className="  ">
									<CustomLabel title={"Phone Number"} />
									<input
										type="tel"
										name="Phone"
										className="bg-white  dark:bg-slate-800 px-3 text-gray-500 dark:text-white border dark:border-slate-600 border-gray-200  dark:shadow-none rounded-md placeholder:text-sm tracking-wide w-full"
										required
									/>
								</div>
								<div className="md:col-span-2 ">
									<CustomLabel title={"Organization Name"} />
									<input
										type="text"
										name="Organization"
										className="bg-white  dark:bg-slate-800 px-3 text-gray-500 dark:text-white border dark:border-slate-600 border-gray-200  dark:shadow-none rounded-md placeholder:text-sm tracking-wide w-full"
									/>
								</div>
								<div className=" md:col-span-2">
									<CustomLabel title={"Describe Your Message"} />
									<textarea
										name="Message"
										className=" w-full bg-white  dark:bg-slate-800 px-3 text-gray-500 dark:text-white border dark:border-slate-600 border-gray-200  dark:shadow-none rounded-md placeholder:text-sm tracking-wide resize-none"
										rows="3"
									></textarea>
								</div>
							</div>
							<button
								disabled={loading === true}
								type="submit"
								className="text-white bg-gray-800 hover:bg-gray-700 duration-500 dark:bg-blue-600 dark:hover:bg-blue-700 py-2.5 text-sm tracking-wide px-6 rounded-md inline-flex items-center gap-4"
							>
								Confirm & Submit{" "}
								{loading && (
									<span className="w-6 h-6 inline-block rounded-full animate-spin border-y border-solid border-white border-t-transparent"></span>
								)}
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ModalForm;
