import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import {blogs} from "../../constant/Constant";
const News = () => {
	return (
		<div className="bg-slate-50 duration-500 dark:bg-slate-900">
			<Navbar />
			<div className="container px-6 xl:px-1 py-24">
				<div className=" mb-5 lg:mb-8">
					<h2 className=" text-slate-800 duration-500 dark:text-white text-2xl lg:text-[32px] leading-[40px] font-bold font-inter  text-left mb-1">
						Press <span className="primary-color"> & Media</span>
					</h2>
					<p className="text-gray-600 dark:text-slate-400 duration-500">
						Ecom Solutions is the go-to source for e-commerce news, trends, marketplace opportunities and automation resources.
					</p>
				</div>
				<div className="grid gap-5 xl:gap-8  sm:grid-cols-2 grid-cols-1 xl:grid-cols-3 ">
					{blogs.map((blog) => (
						<div
							key={blog.id}
							className="overflow-hidden  border-and-shadow bg-white duration-500  dark:bg-gray-800 dark:border-gray-700 dark:shadow-none rounded-lg"
						>
							<img src={blog.img} className="object-cover w-full h-64" alt="" />
							<div className="p-5 ">
								<p className="mb-3 text-xs font-semibold tracking-wide uppercase">
									<span className="text-slate-800 dark:text-gray-200 uppercase duration-500">{blog.newsPaper}</span>
									<span className="text-gray-600 dark:text-slate-400 duration-500"> — {blog.date}</span>
								</p>
								<h2 className="inline-block mb-3 text-2xl font-bold text-slate-800 dark:text-white duration-500">{blog.title}</h2>
								<p className="mb-2 text-gray-600 dark:text-gray-400 text-sm duration-500"> {blog.details}</p>
								<a href={blog.path} rel="noreferrer" target="_blank" className="font-semibold text-blue-600 hover:text-blue-500 duration-300">
									See Details
								</a>
							</div>
						</div>
					))}
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default News;
