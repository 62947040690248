import React from "react";
import AboutUs from "../home/AboutUs";
import BannerSlider from "../home/BannerSlider";
import BusinessStructure from "../home/BusinessStructure";
import FAQ from "../home/FAQ";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Services from "../home/Services";
import Statics from "../home/Statics";
import WorkBenefit from "../home/WorkBenefit";
import WorkFlow from "../home/WorkFlow";
const Home = () => {
	return (
		<div className=" font-roboto pt-16 tracking-wide bg-[#f7fafc] dark:bg-slate-900 duration-500 relative">
			<header id="home">
				<Navbar />
				<BannerSlider />
			</header>
			<main className="container px-6 xl:px-0 min-h-full">
				<Services />
				<WorkFlow />
				<WorkBenefit />
				<BusinessStructure />
				<AboutUs />
				<Statics />
				<FAQ />
			</main>
			<footer>
				<Footer />
			</footer>
		</div>
	);
};

export default Home;
