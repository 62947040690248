import React from "react";

const BenefitCard = ({benefit}) => {
	return (
		<div
			key={benefit.id}
			className=" flex items-start flex-col xl:flex-row dark:hover:bg-[#17243d] dark:hover:border-[#17243d] hover:bg-gray-200 duration-500 cursor-pointer rounded-lg bg-white p-5 xl:h-40 xl:items-center border-and-shadow  gap-5 dark:bg-gray-800 dark:border-gray-700 dark:shadow-none"
		>
			<img src={benefit.icons} alt="" className="w-16" />
			<div>
				<h1 className="text-xl font-inter font-semibold duration-500 text-gray-800 dark:text-white">{benefit.title}</h1>
				<p className="text-gray-500 duration-500 dark:text-gray-400 text-sm mt-2">{benefit.quote}</p>
			</div>
		</div>
	);
};

export default BenefitCard;
