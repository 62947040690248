import React from "react";
import {structureData} from "../../constant/Constant";
const BusinessStructure = () => {
	return (
		<div id="businessInfo" className="">
			{structureData.map((service) => (
				<div
					key={service.step}
					className={`flex flex-col-reverse ${service.class}  items-center gap-10 h-full mt-10 lg:mt-20`}
				>
					<div className="lg:w-1/2 ">
						<h3 className="text-lg text-slate-800 font-inter duration-500 dark:text-white tracking-widest font-medium">
							Step <span className="primary-color font-bold">0{service.step}</span>
						</h3>
						<div className="text-slate-800 dark:text-white duration-500 text-2xl sm:text-[32px] sm:leading-[40px] font-bold  tracking-wide font-inter">
							{service.title2.includes("Account") ? (
								<div className="flex items-center gap-2">
									<h2>{service.title1}</h2>
									<span className="primary-color">{service.title2}</span>
								</div>
							) : (
								<div className="">
									<h2>{service.title1}</h2>
									<span className="primary-color">{service.title2}</span>
								</div>
							)}
						</div>
						<p className="mt-2 text-slate-700 duration-500 dark:text-gray-400">{service.quote}</p>
					</div>
					<div className=" flex justify-center   lg:w-1/2">
						<div className="relative  flex items-center w-5/6">
							<img src={service.mainImg} alt="" className="lg:h-[300px]   mx-auto rounded-lg" />
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default BusinessStructure;
