import React from "react";

const Loader = () => {
	return (
		<div className="h-screen bg-white dark:bg-slate-900 flex items-center justify-center">
			<div className="w-16 h-16 rounded-full animate-spin border-y-2 border-solid border-blue-500 border-t-transparent shadow-md"></div>
		</div>
	);
};

export default Loader;
