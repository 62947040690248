import React, {useRef, useState} from "react";
import {toast} from "react-hot-toast";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import {CustomLabel, SelectBoxTitle} from "../../components/FormComponents";
import {automationFromData} from "../../constant/Constant";
const Automation = () => {
	const [loading, setLoading] = useState(true);
	const formRef = useRef(null);
	const date = new Date();
	const scriptUrl = "https://script.google.com/macros/s/AKfycbxPRtRr6A8cUlTDopGhB_4ZoDSDx6htAsHPlD3OFICeNJtQuOoRHYre30AIJvQbP1pQAg/exec";
	const handleSubmit = (e) => {
		setLoading(false);
		e.preventDefault();
		const form = formRef.current;
		const data = new FormData(form);
		fetch(scriptUrl, {method: "POST", body: data})
			.then((res) => {
				if (res) {
					setLoading(true);
					toast.success("Your appointment has been successfully submitted, Thanks for be with us.", {id: 3, position: "top-right"});
					e.target.reset();
				}
			})
			.catch((err) => console.log(err));
	};
	return (
		<div className="bg-[#f7fafc] dark:bg-slate-900 duration-500">
			<Navbar />
			<div className=" container px-6 xl:px-1 py-24">
				<div>
					<div className=" mb-5 lg:mb-6">
						<h2 className=" text-slate-800 duration-500 dark:text-white text-2xl lg:text-[32px] leading-[40px] font-bold font-inter  text-left mb-1">
							Automation <span className="primary-color">Form</span>
						</h2>
						<p className="text-gray-600 duration-500 dark:text-slate-400">
							Please complete the form below with your information and we will make sure to react to you within 12 hours
						</p>
					</div>
					<form method="post" ref={formRef} className="" onSubmit={handleSubmit}>
						<input name="Date Modified" type="text" defaultValue={date} hidden />
						<div className="grid grid-cols-1 sm:grid-cols-2 gap-4 ">
							<div className="">
								<CustomLabel title={"Full Name"} />
								<input
									type="text"
									name="Full Name"
									className="bg-white duration-500 dark:bg-slate-800 px-3 text-gray-500 dark:text-white border dark:border-slate-600 border-gray-200  dark:shadow-none rounded-md placeholder:text-sm tracking-wide w-full"
									required
								/>
							</div>
							<div className=" ">
								<CustomLabel title={"Email"} />
								<input
									type="email"
									name="Email"
									className="bg-white duration-500 dark:bg-slate-800 px-3 text-gray-500 dark:text-white border dark:border-slate-600 border-gray-200  dark:shadow-none rounded-md placeholder:text-sm tracking-wide w-full"
									required
								/>
							</div>
							<div className="">
								<CustomLabel title={"Phone/ WhatsApp"} />
								<input
									type="tel"
									name="Phone or WhatsApp"
									className="bg-white duration-500 dark:bg-slate-800 px-3 text-gray-500 dark:text-white border dark:border-slate-600 border-gray-200  dark:shadow-none rounded-md placeholder:text-sm tracking-wide w-full"
									required
								/>
							</div>
							<div className="">
								<CustomLabel title={"City"} />
								<input
									type="text"
									name="City"
									className="bg-white duration-500 dark:bg-slate-800 px-3 text-gray-500 dark:text-white border dark:border-slate-600 border-gray-200  dark:shadow-none rounded-md placeholder:text-sm tracking-wide w-full"
									required
								/>
							</div>
							<div className=" sm:col-span-2 ">
								<CustomLabel title={"Country"} />
								<input
									type="text"
									name="Country"
									className="bg-white duration-500 dark:bg-slate-800 px-3 text-gray-500 dark:text-white border dark:border-slate-600 border-gray-200  dark:shadow-none rounded-md placeholder:text-sm tracking-wide w-full"
									required
								/>
							</div>
						</div>
						<div className=" space-y-8 my-10">
							<div className="min-h-[68px]">
								<SelectBoxTitle title={"Do you have any fo the following accounts? (select all that apply) "} />
								<div className="mt-4 space-y-2 select-none text-white">
									{automationFromData[0]?.accounts.map((account) => (
										<div key={account.id} className="flex items-center gap-3">
											<input
												className="border border-gray-200 bg-white dark:bg-slate-800 duration-500 dark:border-slate-600  rounded h-5 w-5 "
												type="checkbox"
												name={account.value}
												id={account.id}
												value="Yes"
											/>
											<label className="text-gray-600 duration-500 dark:text-slate-400 text-sm" htmlFor={account.id}>
												{account.title}
											</label>
										</div>
									))}
								</div>
							</div>
							<div className=" min-h-[100px]">
								<SelectBoxTitle title={"Which service are interested in? (select all that apply) "} />
								<div className="mt-4 space-y-2 gap-y-2 select-none text-white">
									{automationFromData[1]?.services.map((service) => (
										<div key={service.id} className="flex items-center gap-3">
											<input
												className="border border-gray-200 bg-white dark:bg-slate-800 duration-500 dark:border-slate-600  rounded h-5 w-5 "
												type="checkbox"
												name={service.title}
												value="Yes"
												id={service.id}
											/>
											<label className="text-gray-600 duration-500 dark:text-slate-400 text-sm" htmlFor={service.id}>
												{service.title}
											</label>
										</div>
									))}
								</div>
							</div>
							<div className=" min-h-[68px]">
								<SelectBoxTitle title={"What is your credit card limit?"} />
								<div className="mt-4 space-y-2 select-none text-slate-400">
									{automationFromData[3]?.creditLimit.map((credit) => (
										<div key={credit.id} className="flex items-center gap-3">
											{" "}
											<input
												type="radio"
												name="Credit Limit"
												id={credit.id}
												value={credit.title}
												defaultChecked={credit.id === "under-5k"}
												className="w-5 h-5 accent-blue-600 bg-white  duration-500 dark:bg-slate-800 border border-gray-200
											dark:border-slate-600"
											/>
											<label htmlFor={credit.id} className="text-gray-600 duration-500 text-sm dark:text-slate-400">
												{credit.title}
											</label>
										</div>
									))}
								</div>
							</div>
							<div className="min-h-[68px]">
								<SelectBoxTitle title={"Where did you hear about us?"} />
								<div className="mt-4 space-y-2 select-none text-white">
									{automationFromData[2]?.discoverUs.map((discover) => (
										<div key={discover.id} className="flex items-center gap-3">
											<input
												className="border border-gray-200 bg-white dark:bg-slate-800 duration-500 dark:border-slate-600  rounded h-5 w-5 "
												type="checkbox"
												name={discover.value}
												value="Yes"
												id={discover.id}
											/>
											<label className="text-gray-600 dark:text-slate-400 duration-500 text-sm" htmlFor={discover.id}>
												{discover.title}
											</label>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="">
							<textarea
								name="Message"
								className="w-full bg-white  duration-500 dark:bg-slate-800 border border-gray-200 dark:border-slate-600 dark:shadow-none resize-none rounded-md p-3 text-gray-500 dark:text-slate-400"
								rows="5"
								placeholder="Message"
							></textarea>
						</div>

						{loading ? (
							<button type="submit" className="text-white mt-6 bg-blue-600 px-6 py-2 rounded-md">
								Submit Information
							</button>
						) : (
							<button className="text-white mt-6 inline-flex items-center gap-3 bg-blue-600 px-6 py-2 rounded-md">
								<span
									className="w-6 h-6 inline-block rounded-full animate-spin
                    border-y border-solid border-white border-t-transparent"
								></span>
								<span>Please wait, while your request is being processing </span>
							</button>
						)}
					</form>
				</div>
			</div>
			<Footer />
		</div>
	);
};
export default Automation;
