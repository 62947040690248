import React, {useState, useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import {ThemeProvider} from "./components/ThemeProvider";
import Loader from "./components/Loader";
import Home from "./pages/home/Home";
import Automation from "./pages/automation/Automation";
import "./App.css";
import News from "./pages/news/News";
import ClickScrollTop from "./components/ClickScrollTop";
import {Toaster} from "react-hot-toast";
function App() {
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	}, []);
	return (
		<div className="relative">
			<Toaster />
			<ThemeProvider>
				{loading ? (
					<Loader />
				) : (
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="automation" element={<Automation />} />
						<Route path="/press-and-media" element={<News />} />
					</Routes>
				)}
			</ThemeProvider>
			{<ClickScrollTop />}
		</div>
	);
}

export default App;
