import React from "react";
import about_banner from "../../assets/images/about-us.svg";
const AboutUs = () => {
	return (
		<div id="about" className=" mt-10 lg:mt-20 ">
			<div
				className={`flex flex-col-reverse lg:flex-row-reverse  items-center gap-10 h-full text-left `}>
				<div className="lg:w-1/2 ">
					<div className=" ">
						<div className="text-slate-800 duration-500 dark:text-white text-2xl sm:text-[32px] font-bold  tracking-wide flex items-center gap-2  font-inter">
							<h2>About</h2>
							<span className="primary-color">Us</span>
						</div>
					</div>
					<div className="mt-2 text-slate-700 duration-500 dark:text-gray-400">
						<div className=" leading-6">
							<p>We do the hard work. You sit back, relax,</p>
							<p> and collect passive streams.</p>
						</div>
						<p className="mb-5 mt-3">
							We are a full-service Done-For-You business management agency. Our Automation
							company is completely revolutionizing the way people approach the investment
							world. Whether it is your first time investing, or you are a seasoned investor,
							we create profitable passive income streams for you from scratch.
						</p>
						<p>
							We build, operate, and scale your business from the ground up, producing
							passive income with aggressive tactics. The best part is that you can get
							started without going through any kind of learning curve or having to do any of
							the day-to-day tasks that it takes to run one of these massive online
							businesses!
						</p>
					</div>
				</div>
				<div className="h-full lg:w-1/2">
					<div className="h-full">
						<img src={about_banner} alt="" className="h-[300px] w-full rounded-lg" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutUs;
