import React from "react";

const ServiceCard = ({service}) => {
	return (
		<div
			key={service.id}
			className="xl:h-44 hover:bg-gray-200 cursor-pointer dark:hover:bg-[#17243d] dark:hover:border-[#17243d]  duration-500  p-5 border-and-shadow bg-white rounded-lg border border-gray-200  dark:bg-gray-800 dark:border-gray-700 dark:shadow-none"
		>
			<div>
				<img className=" h-12" src={service.icons} alt="" />
			</div>
			<div className="mt-1.5">
				<h4 className=" font-medium font-inter duration-500 text-lg text-slate-800 dark:text-white">{service.title}</h4>
				<p className="text-gray-500 duration-500 dark:text-gray-400 text-sm">{service.quote}</p>
			</div>
		</div>
	);
};

export default ServiceCard;
