import banner_1 from "../assets/images/banner-slider-2.png";
import banner_2 from "../assets/images/banner-slider-1.png";
import banner_3 from "../assets/images/banner.jpeg";
import structure_1 from "../assets/images/structure-1.png";
import structure_2 from "../assets/images/structure-2.png";
import structure_3 from "../assets/images/structure-3.png";
import benefit_1 from "../assets/images/benefit-1.png";
import benefit_2 from "../assets/images/benefit-2.png";
import benefit_3 from "../assets/images/benefit-3.png";
import benefit_4 from "../assets/images/benefit-4.png";
import promise_1 from "../assets/images/promise-1.png";
import promise_2 from "../assets/images/promise-2.png";
import promise_3 from "../assets/images/promise-3.png";
import slider_1 from "../assets/images/mobile-1.jpg";
import slider_2 from "../assets/images/mobile-2.jpg";
import slider_3 from "../assets/images/mobile-3.jpg";
import slider_4 from "../assets/images/mobile-4.jpg";
import slider_5 from "../assets/images/mobile-5.jpg";
import slider_6 from "../assets/images/mobile-6.jpg";
import icons_2 from "../assets/images/amazon-logo.png";
import icons_3 from "../assets/images/ebay-logo.png";
import icons_4 from "../assets/images/shop-logo.png";
import icons_5 from "../assets/images/walmart-logo.png";
import icons_6 from "../assets/images/walmart-wfs.png";
import blogImg_1 from "../assets/images/nayan.webp";
import blogImg_2 from "../assets/images/nayan-2.jpeg";
import blogImg_3 from "../assets/images/group-people.jpeg";
import fb from "../assets/images/facebook.svg";
import linkedin from "../assets/images/linkedin.svg";
import whatsapp from "../assets/images/whatsapp.svg";
import insta from "../assets/images/instagram.svg";
import {UilFacebookF, UilInstagram, UilWhatsapp, UilLinkedinAlt} from "@iconscout/react-unicons";
import {UilLocationPoint, UilMobileAndroid, UilEnvelope} from "@iconscout/react-unicons";
export const homeBannerImg = [
	{id: 1, img: banner_1},
];

export const serviceData = [
	{
		id: 1,
		title: "Amazon Dropshipping Automation",
		quote: "More than 50% of ALL Amazon sales come from third-party sellers. Let us help you become one of them!",
		icons: icons_2,
	},
	{
		id: 2,
		title: "Amazon FBA Automation",
		quote: "Be a passive owner! Leverage Amazon’s fulfillment centers & let us handle all aspects of the business for you.",
		icons: icons_2,
	},
	{
		id: 3,
		title: "eBay Automation",
		quote: "Let Ecom Solutions build and manage your store on the platform that has almost 187 million people active!",
		icons: icons_3,
	},
	{
		id: 4,
		title: "Facebook Shops Automation",
		quote: "Sell products to 240 million people across the United States with Ecom Solutions Facebook automation service.",
		icons: icons_4,
	},
	{
		id: 5,
		title: "Walmart Dropshipping Automation",
		quote: "Sell your products to the 100 million unique visitors that visit Walmart.com every single month.",
		icons: icons_5,
	},
	{
		id: 6,
		title: "Walmart WFS Automation",
		quote: "Take advantage of an experienced automation agency to streamline your Walmart Application Process.",
		icons: icons_6,
	},
];

export const benefitData = [
	{
		id: 1,
		title: "Stress Free Onboarding",
		icons: benefit_1,
		quote: "We walk you through all of the steps needed to apply from an onboarding consultation discussing business setup and creation all the way through a personal guided application call",
	},
	{
		id: 2,
		title: "Hands Free Passive Income",
		icons: benefit_2,
		quote: "No matter what business model you choose that’s right for you we will handle everything for once we're up and running no work is required from you - so you can earn money without having to put your time in",
	},
	{
		id: 3,
		title: "Fully Transparent And Ethically Sound",
		icons: benefit_3,
		quote: "We run an operation that is built on a foundation of strong values and has strong moral leadership. Transparency is key",
	},
	{
		id: 4,
		title: "We Manage Our Teams Directly",
		icons: benefit_4,
		quote: "Choosing Innovative Ecom Solutions means you work directly with us and our team. We do not outsource any of our projects",
	},
];

export const handleThingsData = [
	{id: 1, title: "We build Amazon Automation stores from scratch"},
	{id: 2, title: "Ready-made for generating revenue"},
	{
		id: 3,
		title: "Thing That We Handle",
		handle: [
			{id: 4, title: "Manage supplier relations"},
			{id: 5, title: "Set optimal pricing strategies"},
			{id: 6, title: "Facilitate returns"},
			{id: 7, title: "Identify and mitigate account risk factors"},
			{id: 8, title: "Real-time reports for Amazon dropshipping"},
			{id: 9, title: "FBA clients"},
		],
	},
	{id: 10, title: "And most of all deliver account service excellence by treating our investors as partners"},
	{id: 11, title: "We’re in this together to take pride in making you passive income."},
];

export const mobileSlider = [
	{id: 1, sliderImg: slider_1},
	{id: 2, sliderImg: slider_2},
	{id: 3, sliderImg: slider_3},
	{id: 4, sliderImg: slider_4},
	{id: 5, sliderImg: slider_5},
	{id: 6, sliderImg: slider_6},
];

export const promiseData = [
	{id: 1, img: promise_1, title: "Communication Communication - Constant, Transparent, Genuine, Prompt."},
	{id: 2, img: promise_2, title: "Accountability Accountability - Real-time Reporting, Analytics, Risk Monitoring."},
	{id: 3, img: promise_3, title: "Integrity Integrity - No sales pitch or fast moves. Just honesty and collaboration."},
];

export const structureData = [
	{
		step: 1,
		title1: "Acquire and Build Your",
		title2: "Business Structure",
		quote: "Regardless of the service you choose, the first step is to build your business structure. We must lay down the groundwork and foundation of business such as training the staff, assigning an account manager, gathering tools and software to run the business, and many more!",
		mainImg: structure_1,
		class: "lg:flex-row",
	},
	{
		step: 2,
		title1: "Managing Your",
		title2: "Account",
		quote: "We work on your account daily for at least 8 hours a day. We make sure running according to plan. We provide monthly reports for our clients to see business is progressing.",
		mainImg: structure_2,
		class: "lg:flex-row-reverse",
	},
	{
		step: 3,
		title1: "Scaling Your ",
		title2: "Account",
		quote: " We assure to increase your account’s value by generating a loyal customer base. experienced team specializes in handling everyday tasks seamlessly to grow account and make sure you get the best return on your investment ",
		mainImg: structure_3,
		class: "lg:flex-row",
	},
];

export const staticData = [
	{num: 400, title: "CLIENTS", textColor: "stats-data-1"},
	{num: 300, title: "TEAM MEMBERS", textColor: "stats-data-2"},
	{num: 700, title: "Completed Projects", textColor: "stats-data-4"},
];

export const faqData = [
	{
		id: 1,
		title: "What is dropshipping?",
		quote: "Dropshipping is the model where Ecom Solutions  lists products that other merchants and wholsalers actually posess and ship to our buyers. Dropshipping is great because our clients (and ourselves) do not have the inventory costs that suppliers have. If a product does not sell, we don’t have dead inventory as we never posessed it in the first place!",
	},
	{
		id: 2,
		title: "What does FBA stand for?",
		quote: "Amazon FBA stands for Fulfillment by Amazon. This is the business relationship established with Amazon that allows storage, and fulfillment of products purchased by third party sellers (such as our clients) from all of Amazon’s fulfillment centers nationwide. All products that are stored and part of the FBA program are PRIME eligible.",
	},
	{
		id: 3,
		title: "How much profit can I expect to earn on my store?",
		quote: "Profits vary. Book a strategy call to discuss.",
	},
	{
		id: 4,
		title: "How will I get paid?",
		quote: "You get paid based on the sales of the Amazon automation store. Customers buy from your store that Ecom Solutions has built for your business. The passive income profits are then placed into your seller store account balance. Ecom Solutions has no access to this balance and cannot make transfers. We invoice for our fees after all profits are calculated at the end of the month.",
	},
	{
		id: 5,
		title: "What will be my level of involvement?",
		quote: "You need to do nothing after initial company setup is complete. Sit back, relax, and let the team at Ecom Solutions list and sell products. We manage everything.",
	},
];

export const automationFromData = [
	{
		accounts: [
			{id: "amz-seller", title: "Amazon Seller Account", value: "Amazon Seller"},
			{id: "walmart-seller", title: "Walmart Seller Account", value: "Walmart Seller"},
			{id: "fb-shop", title: "Facebook/Meta Shop", value: "Facebook or Meta Shop"},
			{id: "ebay-seller", title: "eBay Seller Account", value: "eBay Seller"},
		],
	},
	{
		services: [
			{id: "amz-dropshipping", title: "Amazon Dropshipping Automation", value: "amazon-dropshipping-automation"},
			{id: "amz-fba", title: "Amazon FBA Automation", value: "amazon-FBA-automation"},
			{id: "walmart-dropshipping", title: "Walmart Dropshipping Automation", value: "walmart-dropshipping-automation"},
			{id: "fb-shop-automation", title: "Facebook or Meta shop Automation", value: "facebook-shop-automation"},
			{id: "ebay-dropshipping", title: "eBay Dropshipping Automation", value: "ebay-dropshipping-automation"},
		],
	},
	{
		discoverUs: [
			{id: "search-engine", title: "Search Engine (Google, Bing, etc)", value: "Hearing From Search Engine"},
			{id: "referral", title: "Referral (from a friend)", value: "Hearing From Friend"},
			{id: "social", title: "Social (Facebook, Instagram, Linkedin)", value: "Hearing Via Social Media"},
		],
	},
	{
		creditLimit: [
			{id: "under-5k", title: "Under $5000"},
			{id: "5k-20k", title: "$5000 - $20000"},
			{id: "20k-50k", title: "$20000 - $50000"},
			{id: "above-5k", title: "Above $50000"},
		],
	},
];

export const blogs = [
	{
		id: 2,
		img: blogImg_1,
		newsPaper: "৭১ টেলিভিশন ",
		date: "১৩ ডিসেম্বর ২০২২",
		title: "কর্মীদের কোটি টাকা বেতনই দেন নয়ন",
		details:
			"ফ্রিল্যান্সার হিসেবে নিজেকে প্রতিষ্ঠিত করেছেন, নিজের প্রতিষ্ঠান ইকম সলিউশনসে যুক্ত করেছেন ২০০ বাংলাদেশি ও ১০০ ফিলিপিনো তরুণ-তরুণীকে। ইকম সলিউশনস কর্মীদের মাসিক বেতন দেয় প্রায় এক কোটি টাকা।",
		path: "https://ekattor.tv/news/article?article_id=33296&fbclid=IwAR1iRui4WcuSYuxY0U5i83Cdbq9lHjvxy4xbgA4d6lKIPD7uD0Wf_pD2NLc",
	},
	{
		id: 1,
		img: blogImg_3,
		newsPaper: "প্রথম আলো",
		date: "১৭ অক্টোবর ২০২২",
		title: "কর্মীদের কোটি টাকা বেতনই দিচ্ছেন ২৩ বছরের নয়ন",
		details:
			"২৩ বছরের যুবক তৈরি করেছেন তিনশ’ মানুষের কর্মসংস্থানের সুযোগ। এখন নিজের কর্মীদের মাসিক বেতনই দেন প্রায় কোটি টাকা। শুধু ফ্রিল্যান্সিং করে তিন বছরেই মাল্টি মিলিয়নিয়ার এই যুবকের নাম নয়ন হাসান।",
		path: "https://www.prothomalo.com/bangladesh/district/g7zacqb5fj?fbclid=IwAR0dbt_oCjjtls5Eil1X7xHE-DqmcUrIdnRbIFlJfPrJfqBrisqbBbnYn9U",
	},
	{
		id: 3,
		img: blogImg_2,
		newsPaper: "ডেইলি বাংলাদেশ",
		date: "৫ সেপ্টেম্বর ২০২২",
		title: "৩০০ কর্মীকে কোটি টাকা বেতন দেন ২২ বছরের নয়ন",
		details:
			"৫০ বছরে বাংলাদেশ পরিণত হয়েছে উন্নয়নশীল রাষ্ট্রে। তবে, জনসংখ্যা বেড়ে হয়েছে প্রায় তিনগুণ। যা দেশের সম্পদ না হয়ে পরিণত হয়েছে অভিশাপে। বেকারত্ব বেড়েছে, যুবসমাজ ছুটছে চাকরির পেছনে। ঠিক এখানেই ",
		path: "https://www.daily-bangladesh.com/education/336915",
	},
];

export const footerInfo = [
	{
		socialLink: [
			{Icon: UilFacebookF, path: "https://www.facebook.com/info.ecomsolutions ", img: fb},
			{Icon: UilWhatsapp, path: "https://wa.me/+8801710910594", img: whatsapp},
			{Icon: UilLinkedinAlt, path: "https://www.linkedin.com/company/ecomsolutionsbd/about/", img: linkedin},
			{Icon: UilInstagram, path: "https://www.instagram.com", img: insta},
		],
		contactInfo: [
			{Icon: UilLocationPoint, title: "address", subtitle: ["H-68/1, Omor Ali Lane", "West Rampura, Dhaka-1219", "Bangladesh"]},
			{Icon: UilMobileAndroid, title: "Call Us", subtitle: "+8801710910594"},
			{Icon: UilEnvelope, title: "Email", subtitle: "support@ecomsolutionsbd.com"},
		],
		quickLinks: [
			{id: 1, link: "Home", path: "/"},
			{id: 3, link: "Automation", path: "/automation"},
			{id: 2, link: "Press & News", path: "/press-and-media"},
		],
	},
];
