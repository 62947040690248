import React, {useEffect, useState} from "react";
import {UilArrowUp} from "@iconscout/react-unicons";
const ClickScrollTop = () => {
	const [scroll, setScroll] = useState(false);
	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50);
		});
	}, []);
	return (
		<>
			{scroll && (
				<span
					onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}
					className="sm:inline-flex items-center cursor-pointer justify-center w-9 h-9 bg-blue-600 hover:bg-blue-800 duration-500 text-white rounded-full hidden fixed  bottom-10 right-10"
				>
					<UilArrowUp className="w-7 h-7" />
				</span>
			)}
		</>
	);
};
export default ClickScrollTop;
