import React from "react";
import ServiceCard from "../../components/ServiceCard";
import {serviceData} from "../../constant/Constant";
const Services = () => {
	return (
		<section id="services" className="mt-10 lg:mt-20">
			<h2 className="text-slate-800 duration-500 dark:text-white text-2xl lg:text-3xl font-bold tracking-wide mb-5 lg:mb-6 text-left">
				Our Popular <span className="primary-color">Services</span>
			</h2>
			<div className={`grid md:grid-cols-2 lg:grid-cols-3 gap-4 min-h-[368px]`}>
				{serviceData.map((service) => (
					<ServiceCard key={service.id} service={service} />
				))}
			</div>
		</section>
	);
};

export default Services;
