import React from "react";
import BenefitCard from "../../components/BenefitCard";
import {benefitData} from "../../constant/Constant";

const WorkBenefit = () => {
	return (
		<div id="benefits" className="mt-10 lg:mt-20">
			<div className="flex flex-col   gap-1">
				<h2 className="text-slate-800 duration-500 dark:text-white text-2xl lg:text-[32px] lg:leading-[40px] font-bold font-inter  mb-5 lg:mb-6 ">
					Benefits of Working <span className="primary-color">Together With Us </span>
				</h2>
			</div>
			<div className="grid md:grid-cols-2 gap-4 ">
				{benefitData.map((benefit) => (
					<BenefitCard key={benefit.id} benefit={benefit} />
				))}
			</div>
		</div>
	);
};

export default WorkBenefit;
