import React from "react";
import CountUp from "react-countup";
import {staticData} from "../../constant/Constant";
const Statics = () => {
	return (
		<div className="statics-gradient-border rounded-lg mt-10 lg:mt-20">
			<div className="grid grid-cols-2 lg:grid-cols-3   stat-border rounded-lg bg-white duration-500 dark:bg-gray-900 dark:border-slate-600 dark:shadow-none ">
				{staticData.map((stat) => (
					<div key={stat?.num} className={` py-8 lg:py-12 text-center `}>
						<h2 className={`${stat?.textColor} font-inter text-4xl xl:text-5xl   tracking-wider font-bold`}>
							<CountUp end={stat?.num} />+
						</h2>
						<p className="text-gray-500 duration-500 dark:text-gray-400">{stat?.title}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default Statics;
