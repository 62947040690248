import React, {useRef, useState} from "react";
import {UilArrowRight} from "@iconscout/react-unicons";
import {Link} from "react-router-dom";
import {footerInfo} from "../constant/Constant";
import {toast} from "react-hot-toast";
const Footer = () => {
	const [loading, setLoading] = useState(false);
	const scriptUrl = "https://script.google.com/macros/s/AKfycbx0jQDaXAVHg0a7kCGSu89efopQjyT2GGCpQtwE2aNUC9Ft9_75i1GYb5c5b7MQbQW3eQ/exec";
	const formRef = useRef(null);
	const date = new Date();
	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		const form = formRef.current;
		const data = new FormData(form);
		fetch(scriptUrl, {method: "POST", body: data})
			.then((res) => {
				if (res) {
					toast.success("Subscriptions Completed", {id: 2, position: "top-right"});
					setLoading(false);
					e.target.reset();
				}
			})
			.catch((err) => console.log(err));
	};
	return (
		<section className=" bg-slate-900 font-inter  duration-500 ">
			<div className="container px-6 py-10 xl:px-0 ">
				<div className="text-gray-400 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-10 lg:gap-0 text-[15px]  ">
					<div>
						<div className="flex flex-col gap-4 mb-10 border-b border-slate-600">
							<h2 className="text-xl font-medium text-white capitalize">About</h2>
							<span className="inline-block w-[30%] h-[2px] bg-slate-100 rounded-full"></span>{" "}
						</div>
						<p className="flex pr-8 text-sm text-justify ">
							At Ecom Solutions, we work with individuals who are looking to invest while simultaneously prioritizing their freedom
							of time. We utilize the money they entrust with us and create a passive stream of income that will continue to bring in
							profits for them.
						</p>
						<div className="flex items-center gap-3 mt-3  text-gray-400 ">
							{footerInfo[0].socialLink.map(({Icon, path}) => (
								<a
									key={path}
									href={path}
									className="border border-gray-400 inline-flex items-center justify-center p-1 h-7 w-7 rounded-full"
								>
									{" "}
									<Icon className="w-4 h-4" />{" "}
								</a>
							))}
						</div>
					</div>
					<div className="space-y-4">
						<div className="flex flex-col gap-4 mb-10 border-b border-slate-600">
							<h2 className="text-xl font-medium text-white capitalize">Contact Info</h2>
							<span className="inline-block  w-[30%]  h-[2px]  bg-slate-100 rounded-full"></span>{" "}
						</div>
						{footerInfo[0].contactInfo.map(({id, Icon, title, subtitle}) => (
							<div key={Icon} className="flex items-start gap-2 ">
								<Icon className="w-5 text-white" />
								<div>
									<h4 className=" text-white font-medium capitalize -mt-0.5">{title}</h4>
									{Array.isArray(subtitle) ? subtitle.map((addr, index) => <p key={index}>{addr}</p>) : <p>{subtitle }</p>}
								</div>
							</div>
						))}
					</div>
					<div>
						{" "}
						<div className="flex flex-col gap-4 mb-10 border-b border-slate-600">
							<h2 className="text-xl font-medium text-white capitalize">Quick Links</h2>
							<span className="inline-block  w-[30%]  h-[2px]  bg-slate-100 rounded-full"></span>{" "}
						</div>
						<ul className="space-y-4">
							{footerInfo[0].quickLinks.map((link) => (
								<li key={link.id}>
									<Link to={link.path}>{link.link}</Link>
								</li>
							))}
						</ul>
					</div>
					<div className="">
						<div className="flex flex-col gap-4 mb-10 border-b border-slate-600">
							<h2 className="text-xl font-medium text-white capitalize">NewsLetter</h2>
							<span className="inline-block  w-[30%]  h-[2px]  bg-slate-100 rounded-full"></span>{" "}
						</div>
						<h5 className="font-medium text-white uppercase">SubsCribe to our newsletter</h5>
						<p className="mt-2 mb-4">Get the latest news, articles, guides and insights to e-commerce automation</p>
						<form ref={formRef} onSubmit={handleSubmit} className="relative border border-slate-600 h-11">
							<input name="Date Modified" type="text" defaultValue={date} hidden />
							<input
								className="w-full h-full pl-3 text-gray-400 bg-slate-800 placeholder:text-sm xl:placeholder:text-base"
								type="email"
								name="Email"
								placeholder="Subscribe Our Newsletter"
								required
							/>
							<button type="submit" disabled={loading === true}>
								<UilArrowRight className="absolute text-white top-2 right-2" />
							</button>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Footer;
