import React, {useEffect, useState} from "react";
import {UilAlignJustify, UilTimes} from "@iconscout/react-unicons";
import light_logo from "../assets/images/logo-light-mode.svg";
import dark_logo from "../assets/images/logo-dark-mode.svg";
import {Link as TabLink} from "react-scroll";
import {Link, useLocation} from "react-router-dom";
import Toggle from "./Toggle";
import ModalForm from "./ModalForm";

const Navbar = () => {
	const [navOpen, setNavOpen] = useState(false);
	const [openModalForm, setOpenModalForm] = useState(false);
	const [logoChange, setLogoChange] = useState("");
	const [scroll, setScroll] = useState(false);
	const {pathname} = useLocation();

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50);
		});
	}, []);
	useEffect(() => {
		const currentTheme = localStorage.getItem("current-theme");
		if (currentTheme === "dark") {
			setLogoChange(true);
		} else if (currentTheme === "light") {
			setLogoChange(false);
		}
	}, [logoChange]);
	return (
		<nav>
			<div
				className={`fixed top-0 left-0 right-0 bg-white duration-500 dark:bg-slate-900 min-h-[62px] flex items-center  z-50 ${
					scroll ? "shadow-md border-b border-transparent  dark:border-slate-600" : "shadow-none border-none "
				}`}
			>
				<div className="container select-none px-6 xl:px-0 font-inter relative">
					<div className="flex items-center justify-between ">
						{pathname !== "/" ? (
							<Link to="/">
								<div className="min-w-[112px]">
									{logoChange ? (
										<img className="w-28 cursor-pointer" src={dark_logo} alt="" />
									) : (
										<img className="w-28 cursor-pointer" src={light_logo} alt="" />
									)}
								</div>
							</Link>
						) : (
							<TabLink smooth={true} offset={-150} duration={1000} to="home">
								<div className="">
									{logoChange ? (
										<img className="w-28 cursor-pointer" src={dark_logo} alt="" />
									) : (
										<img className="w-28 cursor-pointer" src={light_logo} alt="" />
									)}
								</div>
							</TabLink>
						)}
						<div className="flex  items-center gap-3">
							<div className="lg:hidden">
								<Toggle setLogoChange={setLogoChange} logoChange={logoChange} />
							</div>
							<div
								onClick={() => setNavOpen(!navOpen)}
								className="flex items-center gap-3 lg:hidden cursor-pointer duration-500 text-slate-800 dark:text-white"
							>
								{navOpen ? <UilTimes className="" /> : <UilAlignJustify className="" />}
							</div>
						</div>

						<ul
							className={`font-inter tracking-wide lg:font-medium text-base sm:text-sm   lg:flex lg:items-center lg:pb-0 pb-10 absolute lg:static lg:space-x-[20px]  text-gray-800 dark:text-gray-300   left-0  w-full lg:w-auto space-y-4  pt-4 lg:pt-0 lg:space-y-0 transition-all duration-500 ease-in-out  px-6 lg:px-0 ${
								navOpen
									? "top-[45px] opacity-100 bg-[#f7fafc] dark:bg-slate-900  border-b border-transparent dark:border-slate-600 shadow-lg dark:shadow-none"
									: "top-[-490px] lg:opacity-100 opacity-0"
							}`}
						>
							{pathname === "/" && (
								<li>
									<TabLink smooth={true} offset={-150} duration={1000} to="home" className="cursor-pointer">
										Home
									</TabLink>
								</li>
							)}
							{pathname !== "/" || (
								<li>
									<TabLink smooth={true} offset={-100} duration={1000} to="services" className="cursor-pointer">
										Services
									</TabLink>
								</li>
							)}
							{pathname !== "/" || (
								<li>
									<TabLink smooth={true} offset={-120} duration={1200} to="promises" className="cursor-pointer">
										How it works
									</TabLink>
								</li>
							)}
							{pathname !== "/" || (
								<li>
									<TabLink smooth={true} offset={-100} duration={1200} to="benefits" className="cursor-pointer">
										Benefits
									</TabLink>
								</li>
							)}
							{pathname !== "/" || (
								<li>
									<TabLink smooth={true} offset={-100} duration={1200} to="about" className="cursor-pointer">
										About
									</TabLink>
								</li>
							)}
							{pathname !== "/" || (
								<li>
									<TabLink smooth={true} offset={-100} duration={1200} to="faq" className="cursor-pointer">
										FAQ{" "}
									</TabLink>
								</li>
							)}{" "}
							{pathname !== "/" || (
								<li>
									<Link to="/press-and-media" className="cursor-pointer">
										Press & Media
									</Link>
								</li>
							)}
							{pathname !== "/" || (
								<li>
									<span onClick={() => setOpenModalForm(true)} className="cursor-pointer">
										Contact Us
									</span>
								</li>
							)}
							<li className="hidden lg:block">
								<Toggle setLogoChange={setLogoChange} logoChange={logoChange} />
							</li>
							{pathname !== "/" && (
								<li>
									<Link to="/" className="bg-blue-600 py-2 px-4  text-white rounded-md">
										Back to Home
									</Link>
								</li>
							)}
						</ul>
					</div>
				</div>
			</div>
			{openModalForm && <ModalForm openModalForm={openModalForm} setOpenModalForm={setOpenModalForm} />}
		</nav>
	);
};

export default Navbar;
