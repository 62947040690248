import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation} from "swiper";
import "swiper/css";
import "../../App.css";
import {Link} from "react-router-dom";
import {homeBannerImg} from "../../constant/Constant";
import {UilShop} from "@iconscout/react-unicons";
const BannerSlider = () => {
	return (
		<div>
			<section className="bg-slate-50 dark:bg-slate-900 duration-500 mt-10 lg:mt-16 ">
				<div className="container  flex flex-col-reverse items-center  mx-auto space-y-6 lg:space-y-0   lg:flex-row lg:items-center gap-5 lg:gap-10 px-6 xl:px-0 min-h-[400px] ">
					<div className="lg:w-6/12 ">
						{" "}
						<div className="uppercase tracking-wide">
							<h2 className="primary-color sm:text-xl font-medium">We do the heavy lifting so</h2>
							<h2 className="text-3xl mt-1 xl:text-[39px] dark:text-white font-bold duration-500 text-slate-800">
								{" "}
								you can do the easy living
							</h2>
						</div>
						<p className="text-gray-600 duration-500 dark:text-gray-400  mt-3 mb-6">
							We are a full-service Amazon seller store management agency. We will build, operate, and scale your store from the
							ground up, producing passive income with aggressive tactics at Ecom Solutions.
						</p>
						<Link
							to="/automation"
							className="text-white inline-flex items-center gap-2 bg-blue-600  hover:bg-blue-800 duration-300 px-6 py-3  rounded-md tracking-wide text-sm uppercase font-medium"
						>
							<UilShop className="w-4 h-4" /> Apply for a store
						</Link>
					</div>

					<div className="flex items-center  justify-center w-full h-full lg:w-6/12">
						<Swiper
							spaceBetween={20}
							centeredSlides={true}
							autoplay={{
								delay: 2000,
								disableOnInteraction: false,
							}}
							speed={1000}
							loop
							modules={[Autoplay, Navigation]}
							className="mySwiper rounded-lg"
						>
							{homeBannerImg.map((banner) => (
								<SwiperSlide key={banner.id}>
									<img
										className="mx-auto  rounded-lg h-60 object-cover sm:h-80 xl:h-[360px] w-full lg:mx-0"
										src={banner.img}
										alt=""
									/>
								</SwiperSlide>
							))}
						</Swiper>
					</div>
				</div>
			</section>
		</div>
	);
};

export default BannerSlider;
