import React, {useState} from "react";
import {faqData} from "../../constant/Constant";
const FAQ = () => {
	const [selected, setSelected] = useState(null);
	const toggle = (i) => {
		if (selected === i) {
			return setSelected(null);
		}
		setSelected(i);
	};
	return (
		<div id="faq" className="my-10 lg:my-20">
			<h2 className="text-slate-800 duration-500 dark:text-white text-2xl lg:text-[32px] leading-[40px] font-bold font-inter  mb-5 lg:mb-6 text-left">
				Frequently Asked <span className="primary-color">Questions</span>
			</h2>
			<div className="space-y-3 select-none">
				{faqData.map((q) => (
					<div
						className="bg-white dark:bg-gray-800  border-and-shadow dark:border-gray-700 px-5 dark:shadow-none text-lg font-medium text-slate-800 dark:text-slate-300 rounded-lg cursor-pointer border-and-shadow  transition-all  duration-500 ease-in-out"
						key={q.id}
					>
						<div onClick={() => toggle(q.id)} className="flex items-center justify-between">
							<h2
								className={`${
									selected === q.id ? "pb-3" : "pb-5"
								} transition-all duration-500 pt-5  ease-in-out text-slate-800 dark:text-slate-300 text-base font-medium font-inter tracking-wide`}
							>
								{q.title}
							</h2>
							<div className={`${selected === q.id ? "rotate-180" : "rotate-0"} transition-all duration-500 ease-in-out`}>
								<svg width="17" height="10" viewBox="0 0 17 10" className="icon fill-current">
									<path
										d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
										fill="#3056D3"
										stroke="#3056D3"
									/>
								</svg>
							</div>
						</div>
						<p
							className={`${
								selected === q.id ? "h-64 lg:h-24  " : "h-0 "
							}   font-roboto flex items-start transition-all text-gray-500 bg-white dark:bg-gray-800 duration-500 ease-in-out lg:pr-10 leading-relaxed bg-inherit dark:text-gray-400 font-normal text-base overflow-hidden  `}
						>
							{q.quote}
						</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default FAQ;
